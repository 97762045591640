/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setCustomer, setIsLoadingLoaded, clear } from './actions';
import { fetchLinesAggs } from '../../../api/linesaggs';
import { selectCustomer, selectIsLoaded, selectIsLoading } from '../selectors';

const thunkLoad = () => (dispatch, getState) => {
  const customerName = selectCustomer(getState());

  dispatch(clear());
  dispatch(setCustomer(customerName));
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  fetchLinesAggs(customerName).then((items) => {
		dispatch(set({ items }));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
  const isLoaded = selectIsLoaded(getState());
  const isLoading = selectIsLoading(getState());

  if (!isLoaded && !isLoading) {
    dispatch(thunkLoad());
  }
};

const thunkSetCustomerName = (customerName) => (dispatch) => { 
  dispatch(setCustomer(customerName));
  dispatch(thunkLoad());
}

export { thunkLoad, thunkLoadIfNotLoaded, thunkSetCustomerName };
