/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { selectRootSliceAdmin } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceAdmin(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already lines aggs.
  return slice;
};

export const selectCustomer = (stateAdmin) => {
	const slice = selectThisSlice(stateAdmin);
	const { customer } = slice;
	return customer;
}

export const selectPermissionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { permissionDefinitions } = slice;
	return permissionDefinitions;
};

export const selectMapPermissionsByGroupName = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { mapPermissionsByGroupName } = slice;
	return mapPermissionsByGroupName;
};

export const selectPermissionByGroupName = (stateAdmin, groupName) => {
	const map = selectMapPermissionsByGroupName(stateAdmin);
	return map[groupName];
};

export const selectListPermissionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { listPermissionDefinitions } = slice;
	return listPermissionDefinitions;
};

export const selectMapPermissionsListByAppKey = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { mapPermissionsListByAppKey } = slice;
	return mapPermissionsListByAppKey;
};

export const selectListPermissions = (stateAdmin, appKey) => {
	return selectMapPermissionsListByAppKey(stateAdmin)[appKey];
};

export const selectIsLoadingPermissionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { isLoadingPermissionDefinitions } = slice;
	return isLoadingPermissionDefinitions;
};

export const selectIsLoadedPermissionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { isLoadedPermissionDefinitions } = slice;
	return isLoadedPermissionDefinitions;
};

export const selectHasLoadingErrorPermissionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { hasLoadingErrorPermissionDefinitions } = slice;
	return hasLoadingErrorPermissionDefinitions;
};

export const selectListPermissionDefinitionsForCustomer = createSelector(
  selectCustomer,
  selectListPermissionDefinitions,
  selectPermissionDefinitions,
  (
    customer,
    list,
    definitions
  ) => {
    if (!customer) { 
      return list;
    }

    const hasCustomerOverrides = (appKey) => (definitions[appKey]?.customer_overrides || {})[customer];

    return list.map(app => {
      return !hasCustomerOverrides(app.key) ?
        app :
        (
          {
            ...app,
            permissions: app.customer_overrides[customer]
          }
        );
    }).filter(app => Object.keys(app.permissions).length > 0);
  });

export const selectListPermissionsMapForCustomer = createSelector(
  selectCustomer,
  selectMapPermissionsListByAppKey,
  selectPermissionDefinitions,
  (
    customer,
    listPermissionsMap,
    definitions
  ) => {
    if (!customer) { 
      return listPermissionsMap;
    }

    const hasCustomerOverrides = (appKey) => (definitions[appKey]?.customer_overrides || {})[customer];

    const appKeys = Object.keys(listPermissionsMap);

    return appKeys.reduce((acc, appKey) => {
      acc[appKey] =
        !hasCustomerOverrides(appKey) ?
          listPermissionsMap[appKey].filter(perm => !perm.customerKey) :
          listPermissionsMap[appKey].filter(perm => perm.customerKey === customer);
      return acc;
    }, {});
  });

export const selectListEnabledPermissionDefinitionsByGroupName = (
	state,
	mapEnabledGroupNames
) => {
	const resultList = [];
	const enabledAppKeys = {};
	const isEnabledGroupNamesEmpty =
		Object.keys(mapEnabledGroupNames).length === 0;

	if (isEnabledGroupNamesEmpty) {
		return resultList;
	}

	// identify enabled apps
	const appList = selectListPermissionDefinitions(state);
	appList.forEach(app => {
		const { key } = app;
		const plist = selectListPermissions(state, key);
		if (plist.some(p => mapEnabledGroupNames[p.awsGroupName])) {
			enabledAppKeys[key] = true;
		}
	});

	// build unique permission defintions consisting only of enabled apps and permissions.
	appList.forEach(app => {
		if (enabledAppKeys[app.key]) {
			const clone = { ...app };
			const pList = selectListPermissions(state, clone.key);
			clone.permissions = pList.filter(
				({ awsGroupName }) => mapEnabledGroupNames[awsGroupName]
			);
			resultList.push(clone);
		}
	});

	return resultList;
};
