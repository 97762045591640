/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// LINES AGGREGATES
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS } from './slices/LinesAggs/constants';
import { initialState as LinesAggs } from './slices/LinesAggs/initialState';
import LinesAggsReducerParts from './slices/LinesAggs/reducerParts';

// LINES AGGREGATES FILTERS
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS_FILTERS } from './slices/LinesAggsFilters/constants';
import { initialState as LinesAggsFilters } from './slices/LinesAggsFilters/initialState';
import LinesAggsFiltersReducerParts from './slices/LinesAggsFilters/reducerParts';

// LINES AGGREGATES FILTER - DATE RANGE
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS_FILTER_DATE_RANGE } from './slices/LinesAggsFilterDateRange/constants';
import { initialState as LinesAggsFilterDateRange } from './slices/LinesAggsFilterDateRange/initialState';
import LinesAggsFilterDateRangeReducerParts from './slices/LinesAggsFilterDateRange/reducerParts';

// LINES AGGREGATES COLUMN SETTINGS
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS_COLUMN_SETTINGS } from './slices/LinesAggsColumnSettings/constants';
import { initialState as LinesAggsColumnSettings } from './slices/LinesAggsColumnSettings/initialState';
import LinesAggsColumnSettingsReducerParts from './slices/LinesAggsColumnSettings/reducerParts';

// LINES AGGREGATES FILTER SETTINGS
import { ROOT_SLICE as ROOT_SLICE_LINES_AGGS_FILTER_SETTINGS } from './slices/LinesAggsFilterSettings/constants';
import { initialState as LinesAggsFilterSettings } from './slices/LinesAggsFilterSettings/initialState';
import LinesAggsFilterSettingsReducerParts from './slices/LinesAggsFilterSettings/reducerParts';

// PERCENT PASSING FILTERS
import { ROOT_SLICE as ROOT_SLICE_PERCENT_PASSING_FILTERS } from './slices/PercentPassingFilters/constants';
import { initialState as PercentPassingFilters } from './slices/PercentPassingFilters/initialState';
import PercentPassingFiltersReducerParts from './slices/PercentPassingFilters/reducerParts';

// PERCENT PASSING FILTER - DATE RANGE
import { ROOT_SLICE as ROOT_SLICE_PERCENT_PASSING_FILTER_DATE_RANGE } from './slices/PercentPassingFilterDateRange/constants';
import { initialState as PercentPassingFilterDateRange } from './slices/PercentPassingFilterDateRange/initialState';
import PercentPassingFilterDateRangeReducerParts from './slices/PercentPassingFilterDateRange/reducerParts';

// PERCENT PASSING COLUMN SETTINGS
import { ROOT_SLICE as ROOT_SLICE_PERCENT_PASSING_COLUMN_SETTINGS } from './slices/PercentPassingColumnSettings/constants';
import { initialState as PercentPassingColumnSettings } from './slices/PercentPassingColumnSettings/initialState';
import PercentPassingColumnSettingsReducerParts from './slices/PercentPassingColumnSettings/reducerParts';

// PERCENT PASSING FILTER SETTINGS
import { ROOT_SLICE as ROOT_SLICE_PERCENT_PASSING_FILTER_SETTINGS } from './slices/PercentPassingFilterSettings/constants';
import { initialState as PercentPassingFilterSettings } from './slices/PercentPassingFilterSettings/initialState';
import PercentPassingFilterSettingsReducerParts from './slices/PercentPassingFilterSettings/reducerParts';

// TOTAL MILES FILTERS
import { ROOT_SLICE as ROOT_SLICE_TOTAL_MILES_FILTERS } from './slices/TotalMilesFilters/constants';
import { initialState as TotalMilesFilters } from './slices/TotalMilesFilters/initialState';
import TotalMilesFiltersReducerParts from './slices/TotalMilesFilters/reducerParts';

// TOTAL MILES FILTER - DATE RANGE
import { ROOT_SLICE as ROOT_SLICE_TOTAL_MILES_FILTER_DATE_RANGE } from './slices/TotalMilesFilterDateRange/constants';
import { initialState as TotalMilesFilterDateRange } from './slices/TotalMilesFilterDateRange/initialState';
import TotalMilesFilterDateRangeReducerParts from './slices/TotalMilesFilterDateRange/reducerParts';

// TOTAL MILES COLUMN SETTINGS
import { ROOT_SLICE as ROOT_SLICE_TOTAL_MILES_COLUMN_SETTINGS } from './slices/TotalMilesColumnSettings/constants';
import { initialState as TotalMilesColumnSettings } from './slices/TotalMilesColumnSettings/initialState';
import TotalMilesColumnSettingsReducerParts from './slices/TotalMilesColumnSettings/reducerParts';

// TOTAL MILES FILTER SETTINGS
import { ROOT_SLICE as ROOT_SLICE_TOTAL_MILES_FILTER_SETTINGS } from './slices/TotalMilesFilterSettings/constants';
import { initialState as TotalMilesFilterSettings } from './slices/TotalMilesFilterSettings/initialState';
import TotalMilesFilterSettingsReducerParts from './slices/TotalMilesFilterSettings/reducerParts';

// LINK SURVEYS FILTERS
import { ROOT_SLICE as ROOT_SLICE_LINK_SURVEYS_FILTERS } from './slices/LinkSurveysFilters/constants';
import { initialState as LinkSurveysFilters } from './slices/LinkSurveysFilters/initialState';
import LinkSurveysFiltersReducerParts from './slices/LinkSurveysFilters/reducerParts';

// LINK SURVEYS FILTER - DATE RANGE
import { ROOT_SLICE as ROOT_SLICE_LINK_SURVEYS_FILTER_DATE_RANGE } from './slices/LinkSurveysFilterDateRange/constants';
import { initialState as LinkSurveysFilterDateRange } from './slices/LinkSurveysFilterDateRange/initialState';
import LinkSurveysFilterDateRangeReducerParts from './slices/LinkSurveysFilterDateRange/reducerParts';

// LINK SURVEYS COLUMN SETTINGS
import { ROOT_SLICE as ROOT_SLICE_LINK_SURVEYS_COLUMN_SETTINGS } from './slices/LinkSurveysColumnSettings/constants';
import { initialState as LinkSurveysColumnSettings } from './slices/LinkSurveysColumnSettings/initialState';
import LinkSurveysColumnSettingsReducerParts from './slices/LinkSurveysColumnSettings/reducerParts';

// LINK SURVEYS FILTER SETTINGS
import { ROOT_SLICE as ROOT_SLICE_LINK_SURVEYS_FILTER_SETTINGS } from './slices/LinkSurveysFilterSettings/constants';
import { initialState as LinkSurveysFilterSettings } from './slices/LinkSurveysFilterSettings/initialState';
import LinkSurveysFilterSettingsReducerParts from './slices/LinkSurveysFilterSettings/reducerParts';

// LINK SURVEYS TABLE BEHAVIORS
import { ROOT_SLICE as ROOT_SLICE_LINK_SURVEYS_TABLE_BEHAVIORS } from './slices/LinkSurveysTableBehaviors/constants';
import { initialState as LinkSurveysTableBehaviors } from './slices/LinkSurveysTableBehaviors/initialState';
import LinkSurveysTableBehaviorsReducerParts from './slices/LinkSurveysTableBehaviors/reducerParts';


const initialState = {
  [ROOT_SLICE_LINES_AGGS]: {
    ...LinesAggs
  },
  [ROOT_SLICE_LINES_AGGS_FILTERS]: {
    ...LinesAggsFilters
  },
  [ROOT_SLICE_LINES_AGGS_FILTER_DATE_RANGE]: {
    ...LinesAggsFilterDateRange
  },
  [ROOT_SLICE_LINES_AGGS_COLUMN_SETTINGS]: {
    ...LinesAggsColumnSettings
  },
  [ROOT_SLICE_LINES_AGGS_FILTER_SETTINGS]: {
    ...LinesAggsFilterSettings
  },
  [ROOT_SLICE_PERCENT_PASSING_FILTERS]: {
    ...PercentPassingFilters
  },
  [ROOT_SLICE_PERCENT_PASSING_FILTER_DATE_RANGE]: {
    ...PercentPassingFilterDateRange
  },
  [ROOT_SLICE_PERCENT_PASSING_COLUMN_SETTINGS]: {
    ...PercentPassingColumnSettings
  },
  [ROOT_SLICE_PERCENT_PASSING_FILTER_SETTINGS]: {
    ...PercentPassingFilterSettings
  },
  [ROOT_SLICE_TOTAL_MILES_FILTERS]: {
    ...TotalMilesFilters
  },
  [ROOT_SLICE_TOTAL_MILES_FILTER_DATE_RANGE]: {
    ...TotalMilesFilterDateRange
  },
  [ROOT_SLICE_TOTAL_MILES_COLUMN_SETTINGS]: {
    ...TotalMilesColumnSettings
  },
  [ROOT_SLICE_TOTAL_MILES_FILTER_SETTINGS]: {
    ...TotalMilesFilterSettings
  },
  [ROOT_SLICE_LINK_SURVEYS_FILTERS]: {
    ...LinkSurveysFilters
  },
  [ROOT_SLICE_LINK_SURVEYS_FILTER_DATE_RANGE]: {
    ...LinkSurveysFilterDateRange
  },
  [ROOT_SLICE_LINK_SURVEYS_COLUMN_SETTINGS]: {
    ...LinkSurveysColumnSettings
  },
  [ROOT_SLICE_LINK_SURVEYS_FILTER_SETTINGS]: {
    ...LinkSurveysFilterSettings
  },
  [ROOT_SLICE_LINK_SURVEYS_TABLE_BEHAVIORS]: {
    ...LinkSurveysTableBehaviors
  }
};

const reducerParts = {
  ...LinesAggsReducerParts,

  ...LinesAggsFiltersReducerParts,
  ...LinesAggsFilterDateRangeReducerParts,
  ...LinesAggsColumnSettingsReducerParts,
  ...LinesAggsFilterSettingsReducerParts,

  ...PercentPassingFiltersReducerParts,
  ...PercentPassingFilterDateRangeReducerParts,
  ...PercentPassingColumnSettingsReducerParts,
  ...PercentPassingFilterSettingsReducerParts,

  ...TotalMilesFiltersReducerParts,
  ...TotalMilesFilterDateRangeReducerParts,
  ...TotalMilesColumnSettingsReducerParts,
  ...TotalMilesFilterSettingsReducerParts,

  ...LinkSurveysFiltersReducerParts,
  ...LinkSurveysFilterDateRangeReducerParts,
  ...LinkSurveysColumnSettingsReducerParts,
  ...LinkSurveysFilterSettingsReducerParts,
  ...LinkSurveysTableBehaviorsReducerParts
};

export { ROOT_SLICE } from './constants'
export default handleActions(reducerParts, initialState);
